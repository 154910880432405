import * as React from 'react';
import { AppStyles } from '../style/Styles';
import {
  Button,
  ScrollView,
  TextInput,
  TouchableOpacity
  } from 'react-native';
import { FirebaseContext } from '../../AppState';
import { Ionicons } from '@expo/vector-icons';
import { RootStackParamList } from '../navigation';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Student } from '../types/Types';
import { Text, View } from '../components/Themed';
import { uploadStudentToSync } from '../utility/firebaseFunctions';

type ScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  'StudentEditorScreen'
>;

type ScreenRouteProp = RouteProp<RootStackParamList, 'StudentEditorScreen'>;

type MainScreenProps = {
  navigation: ScreenNavigationProp;
  route: ScreenRouteProp;
};

export function StudentEditorScreen(props: MainScreenProps) {
  const [student, setStudent] = React.useState<Student>();

  const fbContext = React.useContext(FirebaseContext);

  React.useEffect(() => {
    const foundStudent = fbContext.syncState.students.find(
      (i) => i.id === props.route.params.studentID
    );
    if (!foundStudent) {
      return;
    }
    setStudent(foundStudent);
    props.navigation.setOptions({
      title: `${foundStudent.firstName} ${foundStudent.lastName}`,
    });
  }, [fbContext.syncState.students, props.route.params.studentID]);

  if (!student) {
    return null;
  }

  props.navigation.addListener('beforeRemove', (event) => {
    //TODO: warn the user
    modifyStudent(student);
  });

  const modifyStudent = (updatedStudent: Student) => {
    if (!fbContext.login.selectedUserID) {
      return null;
    }
    uploadStudentToSync(fbContext.login.selectedUserID, updatedStudent);
  };

  const deleteStudent = async () => {
    if (!fbContext.login.selectedUserID) {
      return null;
    }
    await uploadStudentToSync(fbContext.login.selectedUserID, {
      ...student,
      deleted: true,
    });
    props.navigation.pop();
  };

  return (
    <View style={AppStyles.container}>
      <ScrollView style={AppStyles.screenScroller}>
        <Text style={AppStyles.h2}>
          {student.firstName.length || student.lastName.length
            ? `${student.firstName} ${student.lastName}`
            : '(No name)'}
        </Text>
        <TouchableOpacity
          style={AppStyles.row}
          onPress={() => {
            if (window.confirm('Delete this student?')) {
              deleteStudent();
            }
          }}
        >
          <Ionicons
            name="ios-trash"
            size={24}
            style={AppStyles.defaultMarginRight}
          />
          <Text>Delete student</Text>
        </TouchableOpacity>
        <View style={AppStyles.separator} />
        <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
          <Text style={AppStyles.defaultMarginRight}>First name:</Text>
          <TextInput
            value={student.firstName}
            style={AppStyles.basicTextInput}
            onChangeText={(text) => setStudent({ ...student, firstName: text })}
          />
        </View>
        <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
          <Text style={AppStyles.defaultMarginRight}>Last name:</Text>
          <TextInput
            value={student.lastName}
            style={[AppStyles.basicTextInput]}
            onChangeText={(text) => setStudent({ ...student, lastName: text })}
          />
        </View>
        <View style={AppStyles.separator} />
        <Text style={[AppStyles.defaultMarginBottom]}>Contact Info:</Text>
        <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
          <Text style={AppStyles.defaultMarginRight}>Phone:</Text>
          <TextInput
            value={student.contactInfo.phone}
            style={[AppStyles.basicTextInput]}
            onChangeText={(text) =>
              setStudent({
                ...student,
                contactInfo: { ...student.contactInfo, phone: text },
              })
            }
          />
        </View>
        <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
          <Text style={AppStyles.defaultMarginRight}>Email:</Text>
          <TextInput
            value={student.contactInfo.email}
            style={[AppStyles.basicTextInput]}
            onChangeText={(text) =>
              setStudent({
                ...student,
                contactInfo: { ...student.contactInfo, email: text },
              })
            }
          />
        </View>
        <View style={AppStyles.separator} />
        <Text style={AppStyles.defaultMarginBottom}>Other info:</Text>
        <TextInput
          multiline={true}
          value={student.metaInfo}
          style={[AppStyles.basicTextInput, AppStyles.multiLineTextInput]}
          onChangeText={(text) => setStudent({ ...student, metaInfo: text })}
        />
        <Button title="Save" onPress={() => modifyStudent(student)} />
      </ScrollView>
    </View>
  );
}
