import { firebaseCurrentUser, firebaseObject } from './firebaseFunctions';

type AddGroupResponse = {
  systemError?: string;
  userError?: string;
  success: boolean;
};

type AddGroupToUserParams = {
  groupID: string;
  accessCode: string;
};

export async function addGroupToUserAndRedeemCode(
  groupID: string,
  accessCode: string
): Promise<AddGroupResponse> {
  const addGroupToUser = firebaseObject()
    .functions()
    .httpsCallable('addGroupToUser');
  const params: AddGroupToUserParams = { groupID, accessCode };

  try {
    const resultData = (await addGroupToUser(params)).data as AddGroupResponse;
    console.log('Got response:');
    console.log(resultData);
    return resultData;
  } catch (error) {
    return { systemError: `Problem connecting: ${error}`, success: false };
  }
}

export async function getFirebaseUserGroup() {
  const uid = firebaseCurrentUser();

  if (!uid) {
    return undefined;
  }

  try {
    const data = (
      await firebaseObject()
        .firestore()
        .collection('users')
        .doc(uid)
        .collection('roles')
        .doc('role')
        .get()
    ).data();
    if (!data) {
      console.log('No data');
      return undefined;
    }

    console.log('From user doc:');
    console.log(data);

    //get the group from the "groups" field
    // const userGroups = data['groups'] as string[];
    // if (!userGroups || !userGroups.length) {
    //   console.log('No user groups found');
    //   return undefined;
    // }

    // const firstUserGroup = userGroups[0];

    const userGroup = data['group'] as string;
    if (!userGroup) {
      console.log('No group found');
      return undefined;
    }

    //now find the role
    const data2 = (
      await firebaseObject()
        .firestore()
        .collection('groups')
        .doc(userGroup)
        .collection('users')
        .doc(uid)
        .get()
    ).data();
    if (!data2) {
      console.log('No data');
      return undefined;
    }

    return { group: userGroup, role: data2['role'] as string };
  } catch (error) {
    console.error('Error getting group:');
    console.error(error);
    throw error;
  }
}
