import * as React from 'react';
import EnhancedModal from 'react-native-modal';
import { AppStyles } from '../style/Styles';
import { AudioView } from './mediaComponents/AudioView';
import { BlockType, LessonBlock } from '../types/Types';
import { CustomBlockEditor } from '../custom/CustomBlockEditor';
import { CustomBlockView } from '../custom/CustomBlockView';
import { getFBDownloadURL } from '../utility/firebaseFunctions';
import { HeadingBlockView, TextBlockView } from './blocks/TextBlockView';
import { ImageBlockEditorView } from './blockEditors/ImageBlockEditorView';
import { ImageBlockView } from './blocks/ImageBlockView';
import { InstructorNoteBlockEditor } from './blockEditors/InstructorNoteBlockEditorView';
import { Ionicons } from '@expo/vector-icons';
import { isEmptyBlock } from '../utility/lessonKeeperFunctions';
import { LinkBlockEditorView } from './blockEditors/LinkBlockEditorView';
import { LinkView } from './mediaComponents/LinkView';
import { ListBlockEditorView } from './blockEditors/ListBlockEditorView';
import { ListBlockView } from './blocks/ListBlockView';
import { LKButton } from './basicComponents/LKButton';
import { PDFView } from './mediaComponents/PDFView';
import { Text, View } from './Themed';
import { TextBlockEditorView } from './blockEditors/TextBlockEditorView';
import { TimePickerView } from './basicComponents/TimePickerView';
import { useKeyPress } from '../utility/reactUtility';
import { useNavigation } from '@react-navigation/native';
import { YouTubeBlockEditorView } from './blockEditors/YouTubeBlockEditorView';
import { YouTubeVideoView } from './mediaComponents/YouTubeVideoView';
import {
  Animated,
  Button,
  Switch,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

export const LessonBlockView: React.FunctionComponent<{
  block: LessonBlock;
  blockIndex: number;
  isLast: boolean;
  lessonID: string;
  instructorID: string;
  commitBlockFunction: (block: LessonBlock) => void;
  moveBlockFunction: (originalIndex: number, shift: number) => void;
  copyBlockFunction: (block: LessonBlock, lessonID: string) => void;
  copyBlockToCurrentLessonFunction: (
    block: LessonBlock,
    fromLessonID: string
  ) => void;
  deleteBlockFunction: (block: LessonBlock) => void;
  setShowEditor: (showEditor: boolean) => void;
  dimmed: boolean;
}> = (props) => {
  const [downloadUrl, setDownloadUrl] = React.useState<string | undefined>();

  const [showMenu, setShowMenu] = React.useState(false);
  const [showDueDateModal, setShowDueDateModal] = React.useState(false);

  useKeyPress('Escape', () => setShowMenu(false));

  React.useEffect(() => {
    let path = props.block.resource?.documentStorageParams?.path;

    if (path) {
      // console.log(
      //   `looking up path for: ${props.instructorID} ${props.lessonID} ${path}`
      // );
      // console.log(props.block.resource);

      getFBDownloadURL(props.instructorID, props.lessonID, path, false, (url) =>
        setDownloadUrl(url)
      );
    }
  }, [props.block]);

  const blockContentBasedOnType = (block: LessonBlock) => {
    if (isEmptyBlock(block)) {
      return (
        <TouchableOpacity
          onPress={() => props.setShowEditor(true)}
          style={AppStyles.emptyBlockSign}
        >
          <Text style={AppStyles.emptyBlockText}>?</Text>
        </TouchableOpacity>
      );
    }
    switch (block.type) {
      case BlockType.heading:
        return <HeadingBlockView block={block} />;
      case BlockType.text:
        return <TextBlockView block={block} />;
      case BlockType.list:
        return <ListBlockView block={block} />;
      case BlockType.link:
        return <LinkView url={block.link ?? ''} caption={block.textContent} />;
      case BlockType.audio:
        return (
          <AudioView caption={block.textContent} audioURL={downloadUrl ?? ''} />
        );
      case BlockType.image:
        return (
          <ImageBlockView url={downloadUrl ?? ''} text={block.textContent} />
        );
      case BlockType.pdf:
      case BlockType.notation:
      case BlockType.drawing:
        return (
          <PDFView caption={block.textContent} pdfURL={downloadUrl ?? ''} />
        );
      case BlockType.youtube:
        return (
          <View style={{ alignSelf: 'center' }}>
            <YouTubeVideoView
              link={block.link ?? ''}
              caption={block.textContent}
            />
          </View>
        );
      case BlockType.divider:
        return <hr style={{ backgroundColor: 'darkgray', width: '95%' }} />;
      case BlockType.instructorNote:
        return (
          <View style={{}}>
            <Text
              style={[AppStyles.defaultMarginBottom, AppStyles.secondaryText]}
            >
              Instructor note: (not visible to the student)
            </Text>
            <Text>{block.textContent}</Text>
          </View>
        );
      case BlockType.custom:
        return <CustomBlockView block={block} />;
    }
  };

  const noEditorsFor: BlockType[] = [
    BlockType.pdf,
    BlockType.audio,
    BlockType.drawing,
    BlockType.notation,
  ];

  const blockMenu = (
    <>
      {!noEditorsFor.includes(props.block.type) && !props.dimmed ? (
        <LKButton
          onPress={() => {
            setShowMenu(false);
            props.setShowEditor(true);
          }}
          title={'Edit'}
          iconName="md-create"
        />
      ) : null}

      {!props.dimmed &&
      props.block.type !== BlockType.divider &&
      props.block.type !== BlockType.instructorNote ? (
        <LKButton
          title="Due date"
          onPress={() => {
            setShowMenu(false);
            setShowDueDateModal(true);
          }}
          iconName="ios-calendar"
        />
      ) : null}

      {props.blockIndex != 0 && !props.dimmed && (
        <LKButton
          onPress={() => {
            props.moveBlockFunction(props.blockIndex, -1);
            setShowMenu(false);
          }}
          title="Move up"
          iconName="ios-arrow-up"
        />
      )}

      {!props.isLast && !props.dimmed && (
        <LKButton
          onPress={() => {
            props.moveBlockFunction(props.blockIndex, 1);
            setShowMenu(false);
          }}
          title="Move down"
          iconName="ios-arrow-down"
        />
      )}
      {!props.dimmed ? (
        <LKButton
          onPress={() => {
            props.copyBlockFunction(props.block, props.lessonID);
            setShowMenu(false);
          }}
          title="Copy block"
          iconName="md-copy"
        />
      ) : (
        <LKButton
          title="Copy to current lesson"
          iconName="md-copy"
          onPress={() => {
            props.copyBlockToCurrentLessonFunction(props.block, props.lessonID);
            setShowMenu(false);
          }}
        />
      )}

      {!props.dimmed ? (
        <LKButton
          onPress={() => props.deleteBlockFunction(props.block)}
          title="Delete"
          destructive={true}
          iconName="ios-trash"
        />
      ) : null}
    </>
  );

  const blockMenuModal = (
    <EnhancedModal
      isVisible={showMenu}
      onBackdropPress={() => setShowMenu(false)}
    >
      <View style={[AppStyles.basicModal, AppStyles.modalPadding]}>
        {blockMenu}
      </View>
    </EnhancedModal>
  );

  const dueDateModal = (
    <EnhancedModal
      isVisible={showDueDateModal}
      onBackdropPress={() => setShowDueDateModal(false)}
    >
      <View style={[AppStyles.basicModal, AppStyles.modalPadding]}>
        <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>
          Due date
        </Text>
        <View
          style={[
            AppStyles.row,
            AppStyles.defaultMarginBottom,
            { justifyContent: 'space-between' },
          ]}
        >
          <Text style={AppStyles.defaultMarginRight}>Use due date?</Text>
          <Switch
            value={props.block.dueDate !== undefined}
            onValueChange={(val) => {
              if (val) {
                props.commitBlockFunction({
                  ...props.block,
                  dueDate: new Date().getTime() / 1000,
                });
              } else {
                let updatedBlock = { ...props.block };
                delete updatedBlock.dueDate;
                props.commitBlockFunction(updatedBlock);
              }
            }}
          />
        </View>
        <View>
          {props.block.dueDate ? (
            <TimePickerView
              showDate={true}
              showTime={false}
              callback={(date) => {
                props.commitBlockFunction({
                  ...props.block,
                  dueDate: date.getTime() / 1000,
                });
                setShowDueDateModal(false);
              }}
              inputTime={
                props.block.dueDate
                  ? new Date(props.block.dueDate * 1000)
                  : new Date()
              }
            />
          ) : null}
        </View>
      </View>
    </EnhancedModal>
  );

  return (
    <React.Fragment>
      {blockMenuModal}
      {dueDateModal}

      {props.block.dueDate ? (
        <Text style={AppStyles.dueDate}>
          Due date: {new Date(props.block.dueDate * 1000).toLocaleDateString()}
        </Text>
      ) : null}
      <View
        style={[AppStyles.block, props.dimmed ? AppStyles.dimmedBlock : {}]}
      >
        <View style={AppStyles.blockButtons}>
          <TouchableOpacity
            onPress={() => {
              setShowMenu(true);
            }}
          >
            <Ionicons
              size={24}
              color="black"
              name="ellipsis-vertical-circle"
              style={AppStyles.defaultMarginRight}
            />
          </TouchableOpacity>
        </View>
        {blockContentBasedOnType(props.block)}
      </View>
    </React.Fragment>
  );
};

export function editorForBlock(
  block: LessonBlock,
  lessonID: string,
  commitBlockFunction: (block: LessonBlock) => void,
  finish: () => void
) {
  switch (block.type) {
    case BlockType.text:
      return (
        <TextBlockEditorView
          block={block}
          headlineOnly={false}
          commitBlockFunction={commitBlockFunction}
          finish={finish}
        />
      );
    case BlockType.heading:
      return (
        <TextBlockEditorView
          block={block}
          headlineOnly={true}
          commitBlockFunction={commitBlockFunction}
          finish={finish}
        />
      );
    case BlockType.list:
      return (
        <ListBlockEditorView
          block={block}
          commitBlockFunction={commitBlockFunction}
          finish={finish}
        />
      );
    case BlockType.youtube:
      return (
        <YouTubeBlockEditorView
          block={block}
          commitBlockFunction={commitBlockFunction}
          finish={finish}
        />
      );
    case BlockType.link:
      return (
        <LinkBlockEditorView
          block={block}
          commitBlockFunction={commitBlockFunction}
          finish={finish}
        />
      );
    case BlockType.image:
      return (
        <ImageBlockEditorView
          block={block}
          lessonID={lessonID}
          commitBlockFunction={commitBlockFunction}
          finish={finish}
        />
      );
    case BlockType.instructorNote:
      return (
        <InstructorNoteBlockEditor
          block={block}
          commitBlockFunction={commitBlockFunction}
          finish={finish}
        />
      );
    case BlockType.custom:
      return (
        <CustomBlockEditor
          commitBlockFunction={commitBlockFunction}
          finish={finish}
          block={block}
        />
      );
  }
}
