import * as firebaseImport from 'firebase/app';
import { generateModifiedDate, getLessonName } from './lessonKeeperFunctions';
import { getEnvironment } from './releaseChannels';
import {
  LessonBlock,
  LessonModel,
  Student,
  BlockType,
  Instructor,
  LessonBlockResponse,
  FirebaseSyncState,
  FirebaseState,
} from '../types/Types';

const debugUid = 'dcVXreJmgdYMiyLc4cle4FRe57p2';

export async function loadFirebase() {
  //load the other packages

  await import('firebase/auth');
  await import('firebase/firestore');
  await import('firebase/storage');
  await import('firebase/functions');

  console.log('Firebase loaded...');

  //initializeFirebaseWithProduction();
  firebase.initializeApp(getEnvironment()?.firebaseConfig);

  firebase
    .firestore()
    .enablePersistence()
    .catch(function (err) {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
}

export function firebaseCurrentUser() {
  //return getDebugID();
  return firebaseImport.auth().currentUser?.uid;
}

function getDebugID() {
  console.log('****USING DEBUG ID******');
  return debugUid;
}

const firebase = firebaseImport;

export function firebaseObject() {
  return firebase;
}

export function firebaseOnAuthStateChanged(
  callback: (user: firebase.User | null) => void
) {
  firebase.auth().onAuthStateChanged(callback);
}

export function firebaseCurrentUserObject() {
  return firebase.auth().currentUser;
}

export function firebaseSignOut() {
  firebase.auth().signOut();
}

export function firebaseSignInWithApple() {
  const provider = new firebase.auth.OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(function (result) {
      // The signed-in user info.
      var user = result.user;
      // You can also get the Apple OAuth Access and ID Tokens.
      // var accessToken = result?.credential?.accessToken;
      // var idToken = result?.credential?.idToken;

      // ...
    })
    .catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;

      // ...
    });
}

export function firebaseSignInWithGoogle() {
  const authProvider = new firebase.auth.GoogleAuthProvider();
  authProvider.setCustomParameters({
    prompt: 'select_account',
  });
  authProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
  firebase
    .auth()
    .signInWithPopup(authProvider)
    .then(function (result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // if (result) {
      //   // var token = result.credential.accessToken;
      //   // The signed-in user info.
      // }
      // var user = result.user;
      // // ...
      //this ends up happening in onAuthStateChanged
      if (result.additionalUserInfo?.isNewUser) {
      }
    })
    .catch(function (error) {
      // Handle Errors here.
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // // The email of the user's account used.
      // var email = error.email;
      // // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential;
      // ...
    });
}

function lessonRecordCollection(uid: string) {
  return firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .collection('lessonRecords');
}

function syncCollection(uid: string) {
  return firebase.firestore().collection('users').doc(uid).collection('sync');
}

function studentSyncCollection(uid: string) {
  return syncCollection(uid).doc('studentSync').collection('studentSync');
}

export function instructorSyncCollection(uid: string) {
  return syncCollection(uid).doc('instructorSync').collection('instructorSync');
}

function lessonSyncCollection(uid: string) {
  return syncCollection(uid).doc('lessonSync').collection('lessonSync');
}

export type RetrieveLessonsCallback = (lessons: LessonModel[]) => void;
export type RetrieveStudentsCallback = (students: Student[]) => void;
export type RetrieveInstructorsCallback = (instructors: Instructor[]) => void;
export type RetrieveResponsesCallback = (
  responses: LessonBlockResponse[]
) => void;

export function retrieveLessons(
  uid: string,
  callback: RetrieveLessonsCallback
) {
  console.log('GETTING LESSONS');
  lessonSyncCollection(uid)
    .orderBy('modifiedDate', 'desc')
    //.limit(10) //TODO: paginate, or remove limit
    // .get() //by using get() rather than onSnapshot(), we sacrifice realtime updates from the server, but get a ton of performance
    // .then((snapshot) => {
    //   console.log('From cache: ' + snapshot.metadata.fromCache);
    //   const lessons = snapshot.docs.map((doc) => {
    //     return (doc.data() as unknown) as LessonModel;
    //   });
    //   callback(lessons);
    // })
    // .catch((error) => {
    //   console.error('Error getting lessons');
    //   console.error(error);
    // });
    .onSnapshot(
      (snapshot) => {
        console.log('From cache: ' + snapshot.metadata.fromCache);
        const lessons = snapshot.docs.map((doc) => {
          return (doc.data() as unknown) as LessonModel;
        });
        callback(lessons);
      },
      (error) => {
        //TODO: display error
        console.error('Error getting lessons');
        console.error(error);
      }
    );
}

export function retrieveStudents(
  uid: string,
  callback: RetrieveStudentsCallback
) {
  studentSyncCollection(uid)
    .orderBy('modifiedDate', 'desc')
    //.limit(10) //TODO: paginate, or remove limit
    .onSnapshot(
      (snapshot) => {
        console.log('From cache: ' + snapshot.metadata.fromCache);
        const students = snapshot.docs.map((doc) => {
          return (doc.data() as unknown) as Student;
        });
        callback(students);
      },
      (error) => {
        //TODO: display error
        console.error('Error getting students');
        console.error(error);
      }
    );
}

export function retrieveInstructors(
  uid: string,
  callback: RetrieveInstructorsCallback
) {
  instructorSyncCollection(uid)
    .orderBy('modifiedDate', 'desc')
    //.limit(10) //TODO: paginate, or remove limit
    .onSnapshot(
      (snapshot) => {
        console.log('From cache: ' + snapshot.metadata.fromCache);
        const instructors = snapshot.docs.map((doc) => {
          return (doc.data() as unknown) as Instructor;
        });
        callback(instructors);
      },
      (error) => {
        //TODO: display error
        console.error('Error getting instructors');
        console.error(error);
      }
    );
}

export function retrieveResponses(
  uid: string,
  lessonID: string,
  callback: RetrieveResponsesCallback
) {
  return firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .collection('responseRecords')
    .doc(lessonID)
    .onSnapshot((snapshot) => {
      const data = snapshot.data();
      if (!data) {
        return;
      }

      const keys = Object.keys(data);

      const responses = keys.map((responseKey) => {
        let modifiedDate = data[responseKey][
          'modifiedDate'
        ] as firebase.firestore.Timestamp;

        return {
          ...(data[responseKey] as LessonBlockResponse),
          modifiedDate: modifiedDate.toDate(),
        };
      });

      callback(responses);
    });
}

export async function uploadLessonToSync(uid: string, lesson: LessonModel) {
  //make sure to update the modified date
  const modifiedDate = generateModifiedDate();
  lesson.modifiedDate = modifiedDate;
  await lessonSyncCollection(uid).doc(lesson.id).set(lesson);
}

export async function uploadStudentToSync(uid: string, student: Student) {
  student.modifiedDate = generateModifiedDate();
  await studentSyncCollection(uid).doc(student.id).set(student);
}

export async function uploadInstructorToSync(
  uid: string,
  instructor: Instructor
) {
  instructor.modifiedDate = generateModifiedDate();
  await instructorSyncCollection(uid).doc(instructor.id).set(instructor);
}

type LessonShareModel = {
  lessonName: string;
  lessonDate: Date;
  lessonID: string;
  studentIDs: string[];
  modified: Date;
  lessonJSON: string;
};

type ExportableStudent = {
  id: string;
  firstName: string;
  lastName: string;
};

type ExportableInstructor = {
  id: string;
  firstName: string;
  lastName: string;
  contactInfo: {
    phone: string;
    email: string;
  };
};

type LessonExportData = {
  lesson: LessonModel;
  students: ExportableStudent[];
  instructor?: ExportableInstructor;
};

export async function uploadLessonToShare(
  uid: string,
  lesson: LessonModel,
  students: Student[],
  instructor?: Instructor
) {
  let filteredLesson = { ...lesson };
  filteredLesson.blocks = filteredLesson.blocks.filter(
    (i) => i.type !== BlockType.instructorNote
  );

  const exportableLesson: LessonExportData = {
    lesson: filteredLesson,
    students: students.map((i) => ({
      id: i.id,
      firstName: i.firstName,
      lastName: i.lastName,
    })),
    instructor: instructor
      ? {
          id: instructor.id,
          firstName: instructor.firstName,
          lastName: instructor.lastName,
          contactInfo: {
            email: instructor.contactInfo.email,
            phone: instructor.contactInfo.phone,
          },
        }
      : undefined,
  };

  const shareModel: LessonShareModel = {
    lessonName: getLessonName(lesson, students),
    lessonDate: new Date(lesson.lessonMeta.lessonDate * 1000),
    lessonID: lesson.id,
    studentIDs: students.map((i) => i.id),
    modified: new Date(),
    lessonJSON: JSON.stringify(exportableLesson),
  };
  await lessonRecordCollection(uid).doc(lesson.id).set(shareModel);
  console.log('Share link:');

  console.log(generateLessonViewerLink(uid, lesson.id));
}

export async function deleteLessonFromShare(uid: string, lessonID: string) {
  await lessonRecordCollection(uid).doc(lessonID).delete();
}

function responseDocument(uid: string, lessonID: string) {
  return firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .collection('responseRecords')
    .doc(lessonID);
}

export async function uploadResponse(
  response: LessonBlockResponse,
  uid: string,
  lessonID: string
) {
  await responseDocument(uid, lessonID)?.set(
    {
      [response.id]: response,
    },
    { merge: true }
  );
}

export async function deleteResponse(
  response: LessonBlockResponse,
  uid: string,
  lessonID: string
) {
  await responseDocument(uid, lessonID)?.set(
    {
      [response.id]: firebase.firestore.FieldValue.delete(),
    },
    { merge: true }
  );
  if (response.fileResource) {
    const ref = getResponseFirebaseUploadRef(
      uid,
      lessonID,
      response.fileResource
    );
    await deleteServerFile(ref);
  }
}

export async function callFirebaseEmailLessonFunction(
  instructorID: string,
  studentID: string,
  lessonID: string,
  studentEmail: string
) {
  const sendLessonCreationEmail = firebaseImport
    .functions()
    .httpsCallable('sendLessonCreationEmail');

  type SendLessonCreationEmailParams = {
    IDs: {
      instructorID: string;
      lessonID: string;
      studentID: string;
    };
    contactInfo: {
      studentEmail: string;
    };
  };

  const params: SendLessonCreationEmailParams = {
    IDs: {
      instructorID,
      studentID,
      lessonID,
    },
    contactInfo: {
      studentEmail,
    },
  };

  const result = await sendLessonCreationEmail(params);
  console.log('Result of send:');
  console.log(result);
  return result.data as { success: boolean; userError?: string };
}

export function generateLessonViewerLink(uid: string, lessonID: string) {
  return `https://viewer.lessonkeeper.app/?instructorID=${uid}&lessonID=${lessonID}`;
}

export function generateStudentPageLink(uid: string, studentID: string) {
  return `https://viewer.lessonkeeper.app/?instructorID=${uid}&studentID=${studentID}`;
}

export async function getUserStorageSize(uid: string) {
  const totalSizeDoc = await firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .collection('fileRecords')
    .doc('__totalSize')
    .get();
  const totalSizeData = totalSizeDoc.data();
  if (!totalSizeData) {
    return -1;
  }
  const filesJSON = totalSizeData['files'];
  if (!filesJSON) {
    return -1;
  }
  const filesDecoded = JSON.parse(filesJSON)['records'] as { size: number }[];
  if (!filesDecoded) {
    return -1;
  }
  return filesDecoded.reduce((acc, item) => acc + item.size, 0);
}

export enum UploadState {
  uploading = 'uploading',
  error = 'error',
  finished = 'finished',
}

export async function deleteServerFile(fileRefName: string) {
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const fileRef = storageRef.child(fileRefName);
  await fileRef.delete();
}

export async function copyServerFileToNewLesson(
  uid: string,
  originalLessonID: string,
  originalPath: string,
  newLessonID: string,
  newFileName: string,

  uploadProgressCallback: (progress: number) => void,
  stateCallback: (state: UploadState) => void,
  errorCallback: (error: string) => void
) {
  const originalRefName = getFirebaseUploadRef(
    uid,
    originalLessonID,
    originalPath
  );

  console.log('Finding for ' + originalRefName);

  const originalRef = firebase.storage().ref().child(originalRefName);
  const downloadURL = await originalRef.getDownloadURL();
  if (!downloadURL) return;

  console.log('Have download URL: ' + downloadURL);

  const response = await makeRequest('GET', 'blob', downloadURL);
  if (response) {
    const blob = response as Blob;

    console.log('Got blob:');
    console.log(blob);

    //upload this blob
    const fileBlob = blobToFile(blob, newFileName);

    console.log('Going to re-upload file:');
    uploadFileToLesson(
      fileBlob,
      newFileName,
      uid,
      newLessonID,
      uploadProgressCallback,
      stateCallback,
      errorCallback
    );
  } else {
    errorCallback('Error downloading');
  }
}

function makeRequest(
  method: string,
  type: XMLHttpRequestResponseType,
  url: string
) {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.responseType = type;
    xhr.open(method, url);
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    xhr.send();
  });
}

const blobToFile = (theBlob: Blob, fileName: string): File => {
  let b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return <File>theBlob;
};

export async function uploadFileToLesson(
  file: File,
  filename: string,
  uid: string,
  lessonID: string,
  uploadProgressCallback: (progress: number) => void,
  stateCallback: (state: UploadState) => void,
  errorCallback: (error: string) => void
) {
  const MAX_FILE_SIZE_MB = 10;
  const MAX_FILE_SIZE = 1024 * 1024 * MAX_FILE_SIZE_MB;

  if (file.size > MAX_FILE_SIZE) {
    errorCallback(
      `I'm sorry, but this file exceeds the maximum size of ${MAX_FILE_SIZE_MB} MB`
    );
    return;
  }

  const userUploadSize = await getUserStorageSize(uid);
  console.log('User upload size: ' + userUploadSize);

  const USER_UPLOAD_SIZE_LIMIT_MB = 1024;
  const USER_UPLOAD_SIZE_LIMIT = 1024 * 1024 * USER_UPLOAD_SIZE_LIMIT_MB;

  if (userUploadSize + file.size >= USER_UPLOAD_SIZE_LIMIT) {
    errorCallback(
      "I'm sorry, this exceeds the storage limit for LessonKeeper with your current plan"
    );
    return;
  }

  const fileRefName = getFirebaseUploadRef(uid, lessonID, filename);

  const storage = firebase.storage();
  const storageRef = storage.ref();
  const fileRef = storageRef.child(fileRefName);
  const uploadTask = fileRef.put(file);
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED,
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log(progress);
      uploadProgressCallback(Math.round(progress * 10) / 10);
      stateCallback(UploadState.uploading);
    },
    (error) => {
      errorCallback(
        'There was an error uploading the file... Please try again.'
      );
      uploadProgressCallback(0);
      stateCallback(UploadState.error);
      console.error(`There was an error uploading the file: (${fileRefName})`);
      console.error(error);
    },
    () => {
      //finished
      console.log('Uploaded file!');
      console.log(uploadTask.snapshot);

      stateCallback(UploadState.finished);
    }
  );
}

export const getFBDownloadURL = (
  instructorID: string,
  lessonID: string,
  filename: string,
  isResponse: boolean,
  callback: (url: string) => void
) => {
  const storage = firebase.storage();
  const storageRef = storage.ref();

  const ref = isResponse
    ? getResponseFirebaseUploadRef(instructorID, lessonID, filename)
    : getFirebaseUploadRef(instructorID, lessonID, filename);
  storageRef
    .child(ref)
    .getDownloadURL()
    .then((url) => {
      callback(url);
    })
    .catch((error) => {
      console.log('Error looking up download URL:');
      console.error(error);
    });
};

export function getResponseFirebaseUploadRef(
  instructorID: string,
  lessonID: string,
  filename: string
): string {
  return `responses/${instructorID}/lesson-${lessonID}/${filename}`;
}

export function getFirebaseUploadRef(
  instructorID: string,
  lessonID: string,
  filename: string
): string {
  return `${instructorID}/lesson-${lessonID}/${filename}`;
}

export function hasSynced(state: FirebaseState) {
  return (
    state.syncState.sync.hasSyncedInstructors &&
    state.syncState.sync.hasSyncedLessons &&
    state.syncState.sync.hasSyncedStudents &&
    state.login.userGroup.synced
  );
}
