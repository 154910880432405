import * as React from 'react';
import { AccessToken } from '../types/Types';
import { AppStyles } from '../style/Styles';
import { editorForBlock } from '../components/BlockView';
import { FirebaseContext } from '../../AppState';
import { LKButton } from '../components/basicComponents/LKButton';
import { RootStackParamList } from '../navigation';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Text, View } from '../components/Themed';
import {
  ActivityIndicator,
  Picker,
  ScrollView,
  TouchableOpacity,
  TextInput,
} from 'react-native';
import {
  deleteAccessCode,
  generateAccessCode,
  getGroupAccessTokens,
  getUsersNameBasedOnInstructor,
  revokeAccessCode,
} from '../utility/firebaseAdminFunctions';

type ScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  'AdminScreen'
>;

type ScreenRouteProp = RouteProp<RootStackParamList, 'AdminScreen'>;

type ScreenProps = {
  navigation: ScreenNavigationProp;
  route: ScreenRouteProp;
};

export function AdminScreen(props: ScreenProps) {
  const fbContext = React.useContext(FirebaseContext);

  const [generateRoleOfType, setGenerateRoleOfType] = React.useState<
    'instructor' | 'groupRoot' | 'admin'
  >('instructor');

  const [userNames, setUserNames] = React.useState<{ [key: string]: string }>(
    {}
  );

  const [accessTokens, setAccessTokens] = React.useState<
    AccessToken[] | undefined
  >(undefined);

  const [isLoadingAccessToken, setIsLoadingAccessToken] = React.useState(false);

  React.useEffect(() => {
    if (fbContext.login.userGroup.isAdmin) {
      getUserNames();
      getAccessTokens();
    }
  }, [fbContext.login.userGroup]);

  async function getUserNames() {
    const promises = (fbContext.login.userGroup?.usersToAdministrate ?? []).map(
      async (i) => {
        const name = await getUsersNameBasedOnInstructor(i.id);
        return {
          ...i,
          name,
        };
      }
    );
    const users = await Promise.all(promises);
    const newUsernames: { [key: string]: string } = {};
    users.forEach((i) => {
      if (i.name) {
        newUsernames[i.id] = i.name;
      }
    });
    setUserNames(newUsernames);
  }

  async function getAccessTokens() {
    if (
      fbContext.login.userGroup.group &&
      fbContext.login.userGroup.role == 'groupRoot'
    ) {
      setAccessTokens(
        await getGroupAccessTokens(fbContext.login.userGroup.group)
      );
    } else {
      setAccessTokens([]);
    }
  }

  if (!fbContext.login.userGroup.isAdmin) {
    return <Text>Not admin</Text>;
  }

  function isCurrentUser(user: string) {
    return fbContext.login.selectedUserID === user;
  }

  function getUsersName(id: string) {
    if (userNames[id]) {
      return userNames[id] + ` (${id})`;
    }
    return id;
  }

  const accessTokensSection = () => {
    if (fbContext.login.userGroup.role != 'groupRoot') {
      return null;
    }
    if (!accessTokens) {
      return (
        <ActivityIndicator
          size="large"
          animating={true}
          style={AppStyles.defaultMarginBottom}
        />
      );
    }

    const sortedTokens = accessTokens.sort(
      (a, b) => a.createdDate.seconds - b.createdDate.seconds
    );

    const redeemedCodes = sortedTokens.filter((i) => i.redeemed);

    const nonRedeemedCodes = sortedTokens.filter((i) => !i.redeemed);

    return (
      <>
        <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>
          Access codes:
        </Text>
        {redeemedCodes ? (
          <>
            <Text style={[AppStyles.h3, AppStyles.defaultMarginBottom]}>
              Redeemed:
            </Text>
            {redeemedCodes.map((i) => (
              <AccessTokenDisplay
                key={i.id}
                token={i}
                usersName={getUsersName(i.associatedUserID ?? '')}
                reloadTokensCallback={getAccessTokens}
                reloadUsersCallback={getUserNames}
              />
            ))}
          </>
        ) : null}

        {nonRedeemedCodes ? (
          <>
            <Text style={[AppStyles.h3, AppStyles.defaultMarginBottom]}>
              Not redeemed:
            </Text>
            {nonRedeemedCodes.map((i) => (
              <AccessTokenDisplay
                key={i.id}
                token={i}
                usersName={getUsersName(i.associatedUserID ?? '')}
                reloadTokensCallback={getAccessTokens}
                reloadUsersCallback={getUserNames}
              />
            ))}
          </>
        ) : null}
      </>
    );
  };

  return (
    <View style={AppStyles.container}>
      <ScrollView contentContainerStyle={AppStyles.screenScroller}>
        <Text style={[AppStyles.h2, { marginBottom: 20 }]}>Choose a user:</Text>
        {(fbContext.login.userGroup.usersToAdministrate ?? []).map((user) => (
          <View key={user.id} style={AppStyles.adminInterfaceUser}>
            <TouchableOpacity
              onPress={() => {
                fbContext.actions.selectUserID(user.id);
              }}
              style={{ backgroundColor: 'clear' }}
            >
              <Text
                style={[isCurrentUser(user.id) ? { fontWeight: 'bold' } : {}]}
              >
                {getUsersName(user.id)}
              </Text>
              <Text>Role: {user.role}</Text>
            </TouchableOpacity>
          </View>
        ))}

        <View style={AppStyles.separator} />

        {accessTokensSection()}

        {fbContext.login.userGroup.role === 'groupRoot' ? (
          <View style={[AppStyles.row]}>
            <LKButton
              disabled={isLoadingAccessToken}
              onPress={() => {
                const group = fbContext.login.userGroup.group;
                if (!group) {
                  return;
                }
                setIsLoadingAccessToken(true);
                generateAccessCode(group, generateRoleOfType).then(() => {
                  setIsLoadingAccessToken(false);
                  getAccessTokens();
                });
              }}
              title="Generate access code"
              style={[AppStyles.defaultMarginRight, { flex: 4 }]}
            />

            <Picker
              selectedValue={generateRoleOfType}
              style={[AppStyles.defaultMarginRight, { flex: 1 }]}
              onValueChange={(itemValue, itemIndex) =>
                setGenerateRoleOfType(itemValue)
              }
            >
              <Picker.Item label="Instructor" value="instructor" />
              <Picker.Item label="Admin" value="admin" />
              <Picker.Item label="Root" value="groupRoot" />
            </Picker>

            {isLoadingAccessToken ? (
              <ActivityIndicator size="small" animating={true} />
            ) : null}
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
}

const AccessTokenDisplay: React.FunctionComponent<{
  token: AccessToken;
  usersName?: string;
  reloadTokensCallback: () => void;
  reloadUsersCallback: () => void;
}> = (props) => {
  const { token, usersName } = props;
  const accessLink = `https://web.lessonkeeper.app/main?accessCode=${token.id}&groupID=${token.groupID}`;
  const textInputRef = React.useRef<TextInput>(null);

  const [loading, setLoading] = React.useState(false);

  return (
    <View
      style={[AppStyles.accessToken, { backgroundColor: 'clear' }]}
      key={token.id}
    >
      {token.redeemed && token.redeemedDate && token.associatedUserID ? (
        <>
          <Text style={{ fontWeight: 'bold' }}>User: {usersName}</Text>
          <Text>
            Redeemed on: {token.redeemedDate.toDate().toLocaleString()}
          </Text>
        </>
      ) : null}
      <Text style={{ fontWeight: token.redeemed ? 'normal' : 'bold' }}>
        ID: {token.id}
      </Text>
      <Text style={{}}>Group: {token.groupID}</Text>
      <Text>Role: {token.userRole}</Text>
      <Text>Created: {token.createdDate.toDate().toLocaleString()}</Text>
      {token.redeemed ? (
        <LKButton
          style={{ width: 150 }}
          title="Revoke access"
          destructive={true}
          onPress={async () => {
            if (!token.associatedUserID) {
              return;
            }
            if (confirm("Do you really want to revoke this user's access?")) {
              setLoading(true);
              await revokeAccessCode(
                token.groupID,
                token.id,
                token.associatedUserID
              );
              setLoading(false);
              props.reloadTokensCallback();
              props.reloadUsersCallback();
            }
          }}
        />
      ) : null}
      {!token.redeemed ? (
        <View style={AppStyles.row}>
          <LKButton
            style={{ width: 150 }}
            title="Copy access link"
            onPress={() => {
              textInputRef.current?.focus();
              if (document.execCommand('copy')) {
                alert('The link has been copied to your clipboard');
              }
            }}
          />
          <TextInput
            selectTextOnFocus={true}
            style={{ width: 1 }}
            value={accessLink}
            ref={textInputRef}
          />
          <LKButton
            style={{ width: 150 }}
            title="Delete token"
            destructive={true}
            onPress={async () => {
              if (confirm('Are you sure you want to delete this code?')) {
                setLoading(true);
                await deleteAccessCode(token.groupID, token.id);
                setLoading(false);
                props.reloadTokensCallback();
              }
            }}
          />
        </View>
      ) : null}
      {loading ? (
        <ActivityIndicator
          size="small"
          animating={true}
          style={AppStyles.defaultMarginTop}
        />
      ) : null}
    </View>
  );
};
