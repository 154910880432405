import * as React from 'react';
import { AppStyles } from '../style/Styles';
import { Button, TextInput, TouchableOpacity } from 'react-native';
import { generateModifiedDate, uuidv4 } from '../utility/lessonKeeperFunctions';
import { LessonBlockResponse } from '../types/Types';
import { Text, View } from './Themed';
import { useKeyPress } from '../utility/reactUtility';
import {
  firebaseCurrentUser,
  uploadResponse,
} from '../utility/firebaseFunctions';

export const CreateResponseView: React.FunctionComponent<{
  lessonOwnerID: string;
  blockID: string;
  lessonID: string;
}> = (props) => {
  const uid = firebaseCurrentUser();
  if (!uid) {
    return null;
  }

  const generateBlankResponse = () => ({
    id: uuidv4(),
    blockID: props.blockID,
    creatorID: uid,
    modifiedDate: new Date(),
    fromInstructor: uid === props.lessonOwnerID,
    textContent: '',
  });

  const [response, setResponse] = React.useState<LessonBlockResponse>(
    generateBlankResponse()
  );

  const [showField, setShowField] = React.useState(false);

  useKeyPress('Escape', () => setShowField(false));

  const submitResponse = async () => {
    console.log('Submit response');

    await uploadResponse(
      { ...response, modifiedDate: new Date() },
      props.lessonOwnerID,
      props.lessonID
    );

    setShowField(false);
    setResponse(generateBlankResponse());
  };

  return (
    <View>
      {!showField && (
        <TouchableOpacity
          onPress={() => setShowField(true)}
          style={AppStyles.replyButton}
        >
          <Text>Reply</Text>
        </TouchableOpacity>
      )}
      {showField && (
        <>
          <TextInput
            multiline={true}
            value={response.textContent}
            onChangeText={(text) =>
              setResponse({ ...response, textContent: text })
            }
            style={[AppStyles.basicTextInput, { height: 100 }]}
          />
          <Button
            onPress={() => {
              submitResponse();
            }}
            title="Submit"
          />
        </>
      )}
    </View>
  );
};
