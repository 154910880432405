import * as React from 'react';
import { AppStyles } from '../style/Styles';
import { AudioView } from './mediaComponents/AudioView';
import { FirebaseContext } from '../../AppState';
import { ImageBlockView } from './blocks/ImageBlockView';
import { Ionicons } from '@expo/vector-icons';
import { LessonBlockResponse } from '../types/Types';
import { PDFView } from './mediaComponents/PDFView';
import { Text, View } from './Themed';
import { TouchableOpacity } from 'react-native';
import {
  firebaseCurrentUser,
  getFBDownloadURL,
} from '../utility/firebaseFunctions';

export const ResponseView: React.FunctionComponent<{
  response: LessonBlockResponse;
  lessonID: string;
  instructorID: string;
  deleteResponseFunction: (response: LessonBlockResponse) => void;
}> = (props) => {
  const { response } = props;
  return (
    <View style={[AppStyles.bordered, AppStyles.response]}>
      <Text>{response.textContent}</Text>
      {response.fromInstructor && <Text>(Instructor comment)</Text>}
      <Text>{response.modifiedDate.toLocaleString()}</Text>
      {response.fileResource ? (
        <LessonBlockResponseFileView
          instructorID={props.instructorID}
          lessonID={props.lessonID}
          path={response.fileResource}
        />
      ) : null}
      <TouchableOpacity
        style={AppStyles.row}
        onPress={() => {
          if (window.confirm('Delete this response?')) {
            props.deleteResponseFunction(response);
          }
        }}
      >
        <Ionicons name="ios-trash" size={24} />
        <Text>Delete</Text>
      </TouchableOpacity>
    </View>
  );
};

const LessonBlockResponseFileView: React.FunctionComponent<{
  instructorID: string;
  lessonID: string;
  path: string;
}> = (props) => {
  const [downloadUrl, setDownloadUrl] = React.useState<string>();
  const [fileType, setFileType] = React.useState<string>();

  React.useEffect(() => {
    getFBDownloadURL(
      props.instructorID,
      props.lessonID,
      props.path,
      true,
      (url) => {
        const periodIndex = url.lastIndexOf('.');
        const questionMarkIndex = url.lastIndexOf('?');
        const fileExt = url
          .substr(periodIndex, questionMarkIndex - periodIndex)
          .substr(1);
        setDownloadUrl(url);
        setFileType(fileExt);
      }
    );
  }, [props.path]);

  const contentDisplay = () => {
    if (!fileType) return null;
    console.log('Rendering response of type: ' + fileType);
    switch (fileType) {
      case 'pdf':
        return <PDFView pdfURL={downloadUrl ?? ''} caption={''} />;
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'png':
        return <ImageBlockView url={downloadUrl ?? ''} text="" />;
      case 'mp3':
      case 'm4a':
      case 'wav':
        return <AudioView audioURL={downloadUrl ?? ''} caption={''} />;
      default:
        return null;
    }
  };

  return <View>{contentDisplay()}</View>;
};
