import { AccessToken, Instructor } from '../types/Types';
import { firebaseObject, instructorSyncCollection } from './firebaseFunctions';

type GetGroupUsersResponse = {
  systemError?: string;
  userError?: string;
  success: boolean;
  users?: string[];
};

type GetGroupUsersParams = {
  groupID: string;
};

export async function retrieveGroupInstructors(groupID: string) {
  const userDocs = await firebaseObject()
    .firestore()
    .collection('groups')
    .doc(groupID)
    .collection('users')
    .get();
  return userDocs.docs.map((i) => ({ id: i.id, role: i.data().role }));
}

//unused
async function FUNCTION____retrieveGroupInstructors(groupID: string) {
  const getGroupUsers = firebaseObject()
    .functions()
    .httpsCallable('getGroupUsers');
  const params: GetGroupUsersParams = { groupID };

  try {
    const resultData = (await getGroupUsers(params))
      .data as GetGroupUsersResponse;

    return (resultData.users ?? []).map((i) => ({ id: i }));
  } catch (error) {
    console.error('Error calling function:');
    console.error(error);
  }
}

export async function getUsersNameBasedOnInstructor(userID: string) {
  const instructorDocCollection = await instructorSyncCollection(userID)
    .orderBy('modifiedDate', 'desc')
    .limit(1)
    .get();
  if (!instructorDocCollection.docs.length) {
    return undefined;
  }
  const instructorDoc = instructorDocCollection.docs[0].data() as Instructor;
  return `${instructorDoc.firstName} ${instructorDoc.lastName}`;
}

type AddAccessTokenResponse = {
  success: boolean;
  systemError?: string;
  userError?: string;
  accessToken?: {
    groupID: string;
    tokenID: string;
  };
};

type AddAccessTokenParams = {
  groupID: string;
  newUserRole: string;
};

export async function generateAccessCode(groupID: string, newUserRole: string) {
  const addAccessToken = firebaseObject()
    .functions()
    .httpsCallable('addAccessToken');
  const params: AddAccessTokenParams = { groupID, newUserRole };

  try {
    const resultData = (await addAccessToken(params))
      .data as AddAccessTokenResponse;
    console.log('Got result:');
    console.log(resultData);
  } catch (error) {
    console.error('Error');
    console.error(error);
  }
}

export async function deleteAccessCode(groupID: string, tokenID: string) {
  const deleteAccessToken = firebaseObject()
    .functions()
    .httpsCallable('deleteAccessToken');
  const params = { groupID, tokenID };
  try {
    const resultData = (await deleteAccessToken(params))
      .data as AddAccessTokenResponse;
    console.log('Got results: ');
    console.log(resultData);
  } catch (error) {
    console.error('Error');
    console.error(error);
  }
}

export async function revokeAccessCode(
  groupID: string,
  tokenID: string,
  targetUserID: string
) {
  const changeUserGroupRole = firebaseObject()
    .functions()
    .httpsCallable('changeUserGroupRole');

  const params = { groupID, role: 'DELETE', targetUserID };

  try {
    const resultData = (await changeUserGroupRole(params)).data as {
      success: boolean;
      systemError?: string;
      userError?: string;
    };
    console.log('Got results from changeUserGroupRole: ');
    console.log(resultData);

    if (!resultData.success) {
      return resultData;
    }
  } catch (error) {
    console.error('Error');
    console.error(error);
  }

  //delete the token
  return await deleteAccessCode(groupID, tokenID);
}

type GetGroupAccessTokensResponse = {
  success: boolean;
  systemError?: string;
  userError?: string;
  accessTokens?: AccessToken[];
};

type GetGroupAccessTokensParams = {
  groupID: string;
};

export async function getGroupAccessTokens(groupID: string) {
  const tokenDocs = await firebaseObject()
    .firestore()
    .collection('groups')
    .doc(groupID)
    .collection('accessCodes')
    .get();
  const tokens: AccessToken[] = tokenDocs.docs.map((i) => {
    const data = i.data();
    return ({
      ...data,
      id: i.id,
      groupID,
    } as unknown) as AccessToken;
  });

  return tokens;
}

//unused
async function FUNCTION__getGroupAccessTokens(groupID: string) {
  const func = firebaseObject()
    .functions()
    .httpsCallable('getGroupAccessTokens');
  const params: GetGroupAccessTokensParams = { groupID };

  try {
    const resultData = (await func(params))
      .data as GetGroupAccessTokensResponse;
    console.log('Got result:');
    console.log(resultData);
    return resultData.accessTokens ?? [];
  } catch (error) {
    console.error('Error');
    console.error(error);
  }

  return [];
}
