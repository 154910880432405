import * as React from 'react';
import EnhancedModal from 'react-native-modal';
import { AppStyles } from '../style/Styles';
import { BlockType, LessonBlock, ListType } from '../types/Types';
import { createEmptyMGSBlock } from '../custom/MGSCustomContent';
import { EnvironmentNames, getEnvironment } from '../utility/releaseChannels';
import { FirebaseContext, PasteboardContext } from '../../AppState';
import { HTMLInputComponent, HTMLInputTypes } from './HTMLInputComponent';
import { Ionicons } from '@expo/vector-icons';
import { LKButton } from './basicComponents/LKButton';
import { ScrollView, TouchableOpacity } from 'react-native';
import { Text, View } from './Themed';
import { useKeyPress } from '../utility/reactUtility';
import {
  generateModifiedDate,
  getBlockThumbnail,
  getBlockTypeTitle,
  isEmptyBlock,
  uuidv4,
} from '../utility/lessonKeeperFunctions';

export const InlineAddBlockButton: React.FunctionComponent<{
  addBlockFunction: (block: LessonBlock, showEditorAfterAdd: boolean) => void;
  addFileFunction: (file: File) => void;
  pasteBlocksFunction: () => void;
  copyBlockToCurrentLessonFunction: (
    block: LessonBlock,
    fromLessonID: string
  ) => void;
}> = (props) => {
  const pasteboardContext = React.useContext(PasteboardContext);
  const fbContext = React.useContext(FirebaseContext);

  const [showChoices, setShowChoices] = React.useState(false);
  const [showBlockLibrary, setShowBlockLibrary] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  useKeyPress('Escape', () => {
    setShowChoices(false);
    setShowBlockLibrary(false);
  });

  const createMGSBlock = () => {
    setShowChoices(false);
    props.addBlockFunction(createEmptyMGSBlock(), true);
  };

  const createBlockOfType = (type: BlockType) => {
    const primitiveBlock: LessonBlock = {
      id: uuidv4(),
      type,
      textContent: '',
      modifiedDate: generateModifiedDate(),
    };

    setShowChoices(false);

    switch (type) {
      case BlockType.list:
        props.addBlockFunction(
          {
            ...primitiveBlock,
            listType: ListType.bullets,
            listItems: [{ title: '', doable: false, done: false }],
          },
          true
        );
        break;
      default:
        const showEditor = type !== BlockType.divider;
        props.addBlockFunction(primitiveBlock, showEditor);
    }
  };

  const addTypes: { type: BlockType; title: string }[] = [
    { type: BlockType.text, title: 'Text' },
    { type: BlockType.heading, title: 'Headline' },
    { type: BlockType.list, title: 'List' },
    { type: BlockType.link, title: 'Link' },
    { type: BlockType.youtube, title: 'YouTube' },
    { type: BlockType.divider, title: 'Divider' },
    { type: BlockType.instructorNote, title: 'Instructor note' },
  ];

  const fileInputChanged = () => {
    console.log('Files:');
    console.log(inputRef?.current?.files);

    const file = inputRef?.current?.files?.item(0);

    if (file) {
      props.addFileFunction(file);
      setShowChoices(false);
    }
  };

  const blockLibraryModal = (
    <EnhancedModal
      isVisible={showBlockLibrary}
      onBackdropPress={() => setShowBlockLibrary(false)}
    >
      <View style={[AppStyles.basicModal]}>
        <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>
          Block library
        </Text>
        {showBlockLibrary ? (
          <ScrollView contentContainerStyle={AppStyles.screenScroller}>
            {fbContext.syncState.lessons
              .filter((i) => !i.deleted)
              .map((i) => ({ lessonID: i.id, blocks: i.blocks }))
              .flatMap((i) =>
                i.blocks.map((j) => ({ lessonID: i.lessonID, block: j }))
              )
              .filter(
                (i) =>
                  !i.block.deleted &&
                  i.block.type !== BlockType.divider &&
                  i.block.modifiedDate //get rid of ones without a modifiedDate that were created during testing
              )
              .filter((i) => !isEmptyBlock(i.block))
              .sort((a, b) => b.block.modifiedDate - a.block.modifiedDate)
              .map((i) => (
                <View
                  key={i.block.id}
                  style={[AppStyles.blockLibraryItem, AppStyles.row]}
                >
                  <TouchableOpacity
                    style={AppStyles.defaultMarginRight}
                    onPress={() => {
                      setShowBlockLibrary(false);
                      props.copyBlockToCurrentLessonFunction(
                        i.block,
                        i.lessonID
                      );
                    }}
                  >
                    <Ionicons name="ios-add-circle" size={24} />
                  </TouchableOpacity>
                  <View>
                    <Text
                      style={[AppStyles.secondaryText, AppStyles.captionText]}
                    >
                      {getBlockTypeTitle(i.block)}
                    </Text>
                    <Text numberOfLines={1}>
                      {getBlockThumbnail(i.block) ||
                        `(${getBlockTypeTitle(i.block)})`}
                    </Text>
                    <Text
                      style={[AppStyles.secondaryText, AppStyles.captionText]}
                    >
                      {new Date(i.block.modifiedDate * 1000).toLocaleString()}
                    </Text>
                  </View>
                </View>
              ))}
          </ScrollView>
        ) : null}
      </View>
    </EnhancedModal>
  );

  const addBlockModal = (
    <EnhancedModal
      isVisible={showChoices}
      onBackdropPress={() => setShowChoices(false)}
    >
      {showChoices ? (
        <View style={AppStyles.addBlockMenu}>
          <ScrollView>
            {addTypes.map((item) => (
              <LKButton
                key={item.type}
                title={item.title}
                onPress={() => createBlockOfType(item.type)}
              />
            ))}
            <HTMLInputComponent
              callbackRef={inputRef}
              onChange={fileInputChanged}
              type={HTMLInputTypes.allMedia}
            />
            <LKButton
              title="Block library"
              onPress={() => {
                setShowChoices(false);
                setShowBlockLibrary(true);
              }}
            />
            {/* {getEnvironment().envName === EnvironmentNames.MGS ? (
            <LKButton
              title="MGS Block"
              onPress={() => {
                createMGSBlock();
              }}
            />
          ) : null} */}
            <LKButton
              title="File Upload"
              iconName="md-cloud-upload"
              onPress={() => {
                inputRef?.current?.click();
              }}
            />
            {pasteboardContext.pasteboard.copiedBlocks.length ? (
              <LKButton
                title={`Paste block${
                  pasteboardContext.pasteboard.copiedBlocks.length > 1
                    ? 's'
                    : ''
                }`}
                onPress={() => {
                  props.pasteBlocksFunction();
                  setShowChoices(false);
                }}
              />
            ) : null}
          </ScrollView>
        </View>
      ) : (
        <View />
      )}
    </EnhancedModal>
  );

  return (
    <View style={{ backgroundColor: 'clear' }}>
      <TouchableOpacity
        onPress={() => setShowChoices(true)}
        style={{ backgroundColor: 'clear' }}
      >
        <View style={{ alignItems: 'center', backgroundColor: 'clear' }}>
          <Ionicons name="ios-add-circle" size={32} />
          <Text>Add block</Text>
        </View>
      </TouchableOpacity>
      {addBlockModal}
      {blockLibraryModal}
    </View>
  );
};
