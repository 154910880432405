import Navigation from './src/navigation';
import React, { useEffect, useReducer, useState } from 'react';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import { ActivityIndicator } from 'react-native';
import { AdministratedUser } from './src/types/Types';
import { AppStyles } from './src/style/Styles';
import { getFirebaseUserGroup } from './src/utility/firebaseUserFunctions';
import { getQueryParameter } from './src/utility/general';
import { retrieveGroupInstructors } from './src/utility/firebaseAdminFunctions';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { StatusBar } from 'expo-status-bar';

import {
  retrieveLessons,
  retrieveStudents,
  retrieveInstructors,
  hasSynced,
  firebaseCurrentUser,
  firebaseSignOut,
  firebaseOnAuthStateChanged,
  loadFirebase,
} from './src/utility/firebaseFunctions';
import {
  syncStateReducer,
  blankFirebaseSyncState,
  pasteboardReducer,
  FirebaseContext,
  PasteboardContext,
} from './AppState';

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  const [userGroup, setUserGroup] = useState<{
    synced: boolean;
    group?: string;
    role?: string;
    isAdmin?: boolean;
    usersToAdministrate?: AdministratedUser[];
  }>({ synced: false });
  const [uid, setUid] = useState<string | undefined>(undefined);
  const [selectedUserID, setSelectedUserID] = useState<string | undefined>();

  const [syncState, syncStateDispatch] = useReducer(
    syncStateReducer,
    blankFirebaseSyncState
  );

  const [pasteboard, pasteboardDispatch] = useReducer(pasteboardReducer, {
    copiedBlocks: [],
  });

  //useEffect so it only gets called once and not every time the component refreshes
  useEffect(() => {
    async function doAsync() {
      await loadFirebase();

      firebaseOnAuthStateChanged(async (user) => {
        if (user) {
          // User is signed in.
          const uid = user.uid;
          // var displayName = user.displayName;
          // var email = user.email;
          // var emailVerified = user.emailVerified;
          // var photoURL = user.photoURL;
          // var isAnonymous = user.isAnonymous;
          // var providerData = user.providerData;
          setUid(firebaseCurrentUser());

          const querySelectedUser = getQueryParameter('selectedUserID');
          if (querySelectedUser) {
            setSelectedUserID(querySelectedUser);
          } else {
            setSelectedUserID(firebaseCurrentUser());
          }

          refreshUserGroup();
        } else {
          // User is signed out.
          // ...
          setUid(undefined);
          setSelectedUserID(undefined);
        }
      });
    }

    doAsync();
  }, []);

  useEffect(() => {
    if (!uid) {
      return;
    }
    if (!selectedUserID) {
      return;
    }

    retrieveLessons(selectedUserID, (lessonsArray) => {
      syncStateDispatch({ type: 'setLessons', lessons: lessonsArray });
    });
    retrieveStudents(selectedUserID, (items) => {
      console.log('Got students:');
      //console.log(items);
      syncStateDispatch({ type: 'setStudents', students: items });
    });
    retrieveInstructors(selectedUserID, (items) => {
      syncStateDispatch({ type: 'setInstructors', instructors: items });
    });
  }, [selectedUserID]);

  const refreshUserGroup = async () => {
    try {
      console.log('Getting user group for ' + firebaseCurrentUser());
      const foundUserGroup = await getFirebaseUserGroup();
      if (!foundUserGroup) {
        setUserGroup({ synced: true });
        return;
      }
      console.log('Found user group...');
      console.log(foundUserGroup);

      const isAdmin =
        foundUserGroup.role === 'groupRoot' || foundUserGroup.role === 'admin';
      let usersToAdministrate: AdministratedUser[] = [];

      if (isAdmin) {
        console.log('Getting group instructors...');
        const groupInstructorsResult = await retrieveGroupInstructors(
          foundUserGroup.group
        );
        console.log('Got group instructors...');
        if (groupInstructorsResult) {
          usersToAdministrate = groupInstructorsResult;
        }
      }

      setUserGroup({
        synced: true,
        ...foundUserGroup,
        isAdmin,
        usersToAdministrate,
      });
    } catch (error) {
      alert(
        'There was an error retrieving the user group ID for this user.  Please try logging in again.'
      );
      console.error(error);
      firebaseSignOut();
    }
  };

  const selectUserID = (uid: string) => {
    setSelectedUserID(uid);
  };

  if (!isLoadingComplete) {
    return (
      <ActivityIndicator
        size="large"
        animating={true}
        style={AppStyles.defaultMarginTop}
      />
    );
  } else {
    return (
      <SafeAreaProvider>
        <FirebaseContext.Provider
          value={{
            login: { uid, userGroup, selectedUserID },
            syncState,
            actions: {
              refreshUserGroup,
              selectUserID,
            },
          }}
        >
          <PasteboardContext.Provider
            value={{
              pasteboard: {
                copiedBlocks: pasteboard.copiedBlocks,
                fromLessonID: pasteboard.fromLessonID,
              },
              dispatch: pasteboardDispatch,
            }}
          >
            <Navigation colorScheme={colorScheme} />
            <StatusBar />
          </PasteboardContext.Provider>
        </FirebaseContext.Provider>
      </SafeAreaProvider>
    );
  }
}
